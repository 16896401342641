const Home = () => {
    return (

        <div className="FinancialTool">
            <header className="Page-header">
                <p>
                    Home
                </p>

            </header>
            <div>
                <p>
                    test
                </p>
            </div>
        </div>
    );
}

export default Home;
