import Button from 'react-bootstrap/Button';
import Form from 'react-bootstrap/Form';
import cashFlowCalculation from './cashFlowCalculation.js'
import React, { useState } from 'react';

const InputForm = () => {
  const [cashFlowResult, setCashFlowResult] = React.useState(0)
  const [showResult, setShowResult] = React.useState(false)


  const calculateCashFlow = (e, updatedAt) => {
    e.preventDefault()

    const vals = e.target

    const freeCashFlow = vals.freeCashFlow.value * 10**6
    const stockAmount = vals.stockAmount.value
    const riskFreeInterest = vals.riskFreeInterest.value/100
    const beta = vals.riskFreeInterest.value
    const premiumRisk = vals.premiumRisk.value
    const interestCost = vals.interestCost.value
    const ownedCapital = vals.ownedCapital.value * 10**6
    const borrowedCapital = vals.borrowedCapital.value * 10**6
    const expectedGrowth = vals.expectedGrowth.value
    const tax = vals.tax.value/100

    const formData = {
      freeCashFlow,
      stockAmount,
      riskFreeInterest,
      beta,
      premiumRisk,
      interestCost,
      ownedCapital,
      borrowedCapital,
      expectedGrowth,
      tax
    }

    if (!Object.values(formData).some(el => el == "")){
      setCashFlowResult(cashFlowCalculation(formData))
      setShowResult(true)
    }
  }

  const Results = () => {
   return( <div id="results" className="cashFlowResults">
    Resultat: {cashFlowResult}
    </div>
   )
  }

  return (
    <div>
      <Form onSubmit={calculateCashFlow}>
        <Form.Group className="mb-3" controlId="freeCashFlow">
          <Form.Label>Fritt kassaflöde (Miljoner)</Form.Label>
          <Form.Control type="number" step="0.01" placeholder="0" />
        </Form.Group>

        <Form.Group className="mb-3" controlId="stockAmount">
          <Form.Label>Antal aktier (st)</Form.Label>
          <Form.Control type="number" placeholder="0" />
        </Form.Group>

        <Form.Group className="mb-3" controlId="riskFreeInterest">
          <Form.Label>Riskfri ränta (%, tex 5 för 5%)</Form.Label>
          <Form.Control type="number" step="0.01" placeholder="0" />
        </Form.Group>

        <Form.Group className="mb-3" controlId="beta">
          <Form.Label>Beta</Form.Label>
          <Form.Control type="number" step="0.001" placeholder="0" />
        </Form.Group>

        <Form.Group className="mb-3" controlId="premiumRisk">
          <Form.Label>Premiumrisk (%, tex 5 för 5%)</Form.Label>
          <Form.Control type="number" step="0.1" placeholder="0" />
        </Form.Group>

        <Form.Group className="mb-3" controlId="interestCost">
          <Form.Label>Räntekostnad (%, tex 5 för 5%)</Form.Label>
          <Form.Control type="number" step="0.1" placeholder="0" />
        </Form.Group>

        <Form.Group className="mb-3" controlId="ownedCapital">
          <Form.Label>Eget kapital</Form.Label>
          <Form.Control type="number" step="0.1" placeholder="0" />
        </Form.Group>

        <Form.Group className="mb-3" controlId="borrowedCapital">
          <Form.Label>Lånat kapital</Form.Label>
          <Form.Control type="number" step="0.1" placeholder="0" />
        </Form.Group>

        <Form.Group className="mb-3" controlId="expectedGrowth">
          <Form.Label>Förväntad tillväxt (%, tex 5 för 5%)</Form.Label>
          <Form.Control type="number" step="0.1" placeholder="0" />
        </Form.Group>

        <Form.Group className="mb-3" controlId="tax">
          <Form.Label>Skatt (%, tex 5 för 5%)</Form.Label>
          <Form.Control type="number" step="0.1" defaultValue={20} />
        </Form.Group>

        <Button variant="primary" type="submit" >
          Submit
        </Button>
      </Form>

      <div>
        {showResult ? <Results /> : null}
      </div>
    </div>
  );
}




export default InputForm;