import { Route, BrowserRouter, Routes } from 'react-router-dom';
import './App.scss';
import 'bootstrap/dist/css/bootstrap.min.css';
import Navigation from './Components/Navigation';

import FinancialTool from './pages/MyTools/FinancialTool';

import About from './pages/About';
import Home from './pages/Home';
import Blog from './pages/Blog';

const App = () => {
  return (
    <div className="App">
      <BrowserRouter>
        <Navigation />
        <Routes>
          <Route path="/" element={<Home />} />
          <Route path="/home" element={<Home />} />
          <Route path="/blog" element={<Blog />} />
          <Route path="/about" element={<About />} />
          <Route path="/tools/finance" element={<FinancialTool />} />
        </Routes>
      </BrowserRouter>
    </div>
  );
}

export default App;
