const createFreeCashFlowList = (startVal,increase,n=5) => {
    var cashFlows = []
    for (let i = 1; i <= n; i++) {
        cashFlows.push(startVal *((1+(increase)) **i))
    }
    return cashFlows
}

const calcAverageCashFlowDiff = (cashFlows) => {
    var sum = 0
    for (let i = 0; i < cashFlows.length-1; i++) {
        sum += cashFlows[i+1] - cashFlows[i]
    }
    return sum/cashFlows.length
}

const discInterest = (riskFreeInterest,beta,premiumRisk) => {
    return riskFreeInterest + (beta * premiumRisk)
}

const averageCapitalCost = (interestCost,tax,ownCapital,borrowedCapital,discInter) => {
    const totalCapital = ownCapital + borrowedCapital
    const term1 = interestCost * (1-(tax))
    const term2 = ownCapital/totalCapital
    const term3 = borrowedCapital/totalCapital
    return ((term1*term2) + (term3 * discInter))
}

const presentValue = (freeCashFlow,avgCapitalCost) => {
    var sum = 0
    for (let i = 0; i < freeCashFlow.length; i++) {
        sum += freeCashFlow[i]/((1+avgCapitalCost)**(i+1))
    }
    return sum
}

const horizonValue = (freeCashFlow,avgCapitalCost,expectedGrowth,bnp) => {

    return (freeCashFlow[freeCashFlow.length-1]*(1+expectedGrowth))/(avgCapitalCost - bnp)
}

const presentHorizonValue =(horizonValue,avgCapitalCost,years=5) =>{
    return (horizonValue/(1+avgCapitalCost)**years)
}

const cashFlowCalculation = (formData) => {

    const cashFlows = createFreeCashFlowList(formData.freeCashFlow,formData.expectedGrowth)

    const averageCashFlowChange = calcAverageCashFlowDiff(cashFlows)

    const discInt = discInterest(formData.riskFreeInterest,formData.beta,formData.premiumRisk)

    console.log(discInt)
    const avgCapCost = averageCapitalCost(formData.interestCost,formData.tax,formData.ownedCapital,formData.borrowedCapital,discInt)

    console.log(avgCapCost)
    const presentVal = presentValue(cashFlows,avgCapCost)

    console.log(presentVal)
    const horizonVal = horizonValue(cashFlows,avgCapCost,formData.expectedGrowth,0.02)

    console.log(horizonVal)
    const presentHorizonVal = presentHorizonValue(horizonVal,avgCapCost)

    console.log(presentHorizonVal)
    const companyVal = presentHorizonVal + presentVal

    console.log(companyVal)
    const stockValue = companyVal - formData.borrowedCapital

    console.log(stockValue)

    return Math.round(stockValue/formData.stockAmount * 100) / 100
}

export default cashFlowCalculation;
