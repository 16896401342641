import { Dropdown } from "react-bootstrap";
import CashFlowAnalysis from "./CashFlowAnalysis";
import InputForm from "./CashFlowAnalysis/InputForm";
import Accordion from 'react-bootstrap/Accordion';

const FinancialTool = () => {
    return (
        <div className="FinancialTool">
            <header>
                <h2>Finance</h2>
            </header>

            <CashFlowAnalysis />

        </div>
    );
}

export default FinancialTool;
