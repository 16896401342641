
const About = () => {
    return (
        <div className="About">
            <header className="Page-header">
                <p>
                    hell
                </p>

            </header>
        </div>
    );
}

export default About;
