import InputForm from "./InputForm";
import Accordion from 'react-bootstrap/Accordion';

const CashFlowAnalysis = () => {
    return (
        <div className="FinancialTool">
            <Accordion defaultActiveKey="0">
                <Accordion.Item eventKey="0">
                    <Accordion.Header>Kassaflödesanalys</Accordion.Header>
                    <Accordion.Body>
                        <InputForm />
                    </Accordion.Body>
                </Accordion.Item>
            </Accordion>

        </div>
    );
}

export default CashFlowAnalysis;
