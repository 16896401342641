const Blog = () => {
    return (
        <div className="About">
            <header className="Page-header">
                <p>
                    Blog
                </p>

            </header>
        </div>
    );
}

export default Blog;
